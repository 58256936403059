<template>
  <v-dialog v-model="dialog" persistent max-width="550">
    <v-card>
      <v-card-title class="font-weight-bold" style="font-family: 'Montserrat';">
        Subir certificado
      </v-card-title>
      <v-divider class="my-0 mb-4"></v-divider>
      <v-card-text class="px-8">
        <v-subheader class="mb-1"
          >Gravamen: {{ gravamen.toUpperCase() }}</v-subheader
        >
        <v-form ref="form">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(fechaPresentacion)"
                label="Fecha de presentación"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :rules="[(v) => !!v || 'La fecha de presentacion es requerida']"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaPresentacion"
              no-title
              @input="menu1 = false"
              dark
              color="green"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu1"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(fechaCaducidad)"
                label="Fecha de caducidad"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaCaducidad"
              no-title
              dark
              color="red"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <v-file-input
            accept="image/*"
            label="Certificado"
            v-model="file"
            :rules="[(v) => !!v || 'El certificado es requerido']"
          >
          </v-file-input>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4 body-1"
          text
          @click="closeDialog()"
          style="font-family: 'Montserrat' !important;"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="green darken-2"
          text
          class="font-weight-bold body-1"
          style="font-family: 'Montserrat' !important;"
          @click="createCertificado()"
          :loading="loading"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { retencionesService } from "@/services/administrar";
import formatDate from "@/utils/formatDate";
export default {
  name: "SubirCertificado",
  data() {
    return {
      menu1: false,
      menu2: false,
      fechaPresentacion: "",
      fechaCaducidad: "",
      idRetencion: null,
      idCliente: null,
      gravamen: "",
      file: null,

      dialog: false,
      loading: false,

      formatDate,
    };
  },

  mounted() {
    this.$on("showCertificado", (datos) => {
      this.dialog = true;

      this.idRetencion = datos.idRetencion;
      this.idCliente = datos.idCliente;
      this.gravamen = datos.gravamen;
    });

    // console.log("certificados: ", retencionesService);
  },

  methods: {
    async createCertificado() {
      // NOMBRES DE ARCHIVOS
      // retencion_iva
      // retencion_ganancias
      // retencion_iibb

      if (this.$refs.form.validate()) {
        try {
          const formData = new FormData();
          formData.append("file", this.file);
          formData.append("retencion_id", this.idRetencion);
          formData.append("fecha_presentacion", this.fechaPresentacion);

          if (this.fechaCaducidad) {
            formData.append("fecha_caducidad", this.fechaCaducidad);
          }
          formData.append("cliente_id", this.idCliente);
          formData.append("tipo_retencion", `retencion_${this.gravamen}`);

          this.loading = true;

          const data = await retencionesService.createCertificado(formData);

          this.$emit("refreshData");
          this.loading = false;
          this.dialog = false;
          this.$toastr.s(data.message);
          this.$refs.form.reset();
        } catch (error) {
          // console.error("error: ", error);
          this.loading = false;
          this.$toastr.e("Error al guardar certificado");
        }
      }
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
