<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable persistent max-width="1100">
      <v-card>
        <v-card-title
          style="font-family: 'Montserrat';"
          class="font-weight-bold mb-2"
        >
          Seleccione una retención
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            class="font-weight-regular"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-card-text style="height: 460px;" class="pa-0">
          <v-data-table
            v-model="retencion"
            :headers="headers"
            :items="retenciones"
            :loading="loading"
            :search="search"
            loading-text="Cargando retenciones...."
            no-data-text="Sin retenciones"
            no-results-text="Sin coincidencias"
            class="elevation-0 mt-3 tabla"
            disable-sort
            hide-default-footer
            checkbox-color="green"
            show-select
            disable-pagination
            :single-select="true"
            @click:row="(item) => (retencion = [item])"
          >
            <template v-slot:item.condicion="{ item }">
              {{ item.condicion.gravamen.gravamen }}
            </template>
            <template v-slot:item.gravamen="{ item }">
              {{ item.condicion.gravamen.descripcion }}
            </template>

            <template v-slot:item.minorista="{ item }">
              <v-chip :color="item.minorista ? 'green' : 'red'" dark>
                {{ item.minorista ? "Si" : "No" }}
              </v-chip>
            </template>

            <template v-slot:item.rg2854="{ item }">
              <v-chip :color="item.rg2854 ? 'green' : 'red'" dark>
                {{ item.rg2854 ? "Si" : "No" }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" text @click="reset()">
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            class="font-weight-bold"
            text
            @click="asignarRetencion()"
            style="font-family: 'Montserrat';"
            :disabled="retencion.length === 0"
            :loading="loading"
          >
            Asignar retención
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { retencionesService } from "@/services/administrar";
export default {
  name: "AsignarRetenciones",
  props: { clienteId: Number },
  data() {
    return {
      dialog: false,
      loading: false,
      retenciones: [],
      headers: [
        { text: "Gravamen", value: "condicion" },
        { text: "Gravamen descripción", value: "gravamen" },
        {
          text: "Canal de pago",
          value: "canal_de_pago",
        },
        { text: "Alícuota", value: "alicuota" },
        { text: "Alícuota opcional", value: "alicuota_opcional" },
        { text: "Rg2854", value: "rg2854" },
        { text: "Minorista", value: "minorista" },
        { text: "Monto mínimo", value: "monto_minimo" },
      ],
      retencion: [],
      search: "",
    };
  },

  mounted() {
    this.$on("showDialog", () => (this.dialog = true));
    this.$on("refreshData", () => this.getRetenciones());
    this.getRetenciones();
  },

  methods: {
    async getRetenciones() {
      const ClienteId = this.$route.params.cliente_id;
      this.loading = true;

      try {
        const resp = await retencionesService.getDataById({
          ClienteId,
          Unlinked: true, // para obtener las retenciones que no estan asociadas al usuario
        });

        // alert("refresh");

        this.retenciones = resp.data?.retenciones || [];
        this.loading = false;
      } catch (error) {
        // console.error(error);
        this.loading = false;
      }
    },

    async asignarRetencion() {
      const body = {
        RetencionId: this.retencion[0].id,
        ClienteId: this.clienteId,
      };

      // pidiendo confirmación de usuario 🤔
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Esta seguro?",
        text: "Esta acción asignará la retención al cliente",
        icon: "info",
        showDenyButton: true,
        confirmButtonText: "Si, continuar",
        denyButtonText: "Cancelar",
      });

      if (isConfirmed) {
        // si el usuario confirma ✅ -> llamar endpoint 🔥
        try {
          this.loading = true;
          const resp = await retencionesService.asignarRetencion(body);

          this.$toastr.s(resp.message);
          this.reset();
          this.dialog = false;
          this.$emit("refreshData");

          this.getRetenciones();
        } catch (error) {
          // console.error(error);
          this.$toastr.e(error.response.data.message);
          this.loading = false;
        }
      }
    },

    reset() {
      this.retencion = [];
      this.search = "";
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.tabla ::v-deep.v-data-table__selected {
  background: #e8f5e9 !important;
}
.tabla ::v-deep tr {
  cursor: pointer;
}
</style>
