<template>
  <div class="content">
    <div class="header-container">
      <v-btn fab dark x-small color="primary">
        <router-link to="/administrar-clientes">
          <v-icon dark> arrow_back</v-icon>
        </router-link>
      </v-btn>

      <v-btn color="primary" dark @click="showDialog">
        Asignar retención
      </v-btn>
    </div>

    <LayoutPage :title="`Retenciones del cliente: ${cliente.cliente}`">
      <v-data-table
        :headers="headers"
        :items="retenciones"
        :loading="loading"
        loading-text="Cargando retenciones...."
        no-data-text="Sin retenciones"
        class="elevation-0 mt-3 tabla"
        @page-count="pageCount = $event"
        :page.sync="page"
        :items-per-page="rowsPerPage"
        disable-sort
        hide-default-footer
        checkbox-color="green"
        :item-class="rowColor"
      >
        <template v-slot:item.condicion="{ item }">
          {{ item.condicion.gravamen.gravamen }}
        </template>
        <template v-slot:item.gravamen="{ item }">
          {{ item.condicion.gravamen.descripcion }}
        </template>

        <template v-slot:item.minorista="{ item }">
          <v-chip :color="item.minorista ? 'green' : 'red'" dark>
            {{ item.minorista ? "Si" : "No" }}
          </v-chip>
        </template>

        <template v-slot:item.rg2854="{ item }">
          <v-chip :color="item.rg2854 ? 'green' : 'red'" dark>
            {{ item.rg2854 ? "Si" : "No" }}
          </v-chip>
        </template>

        <template v-slot:item.fechaCaducidad="{ item }">
          {{
            item.certificados
              ? formatDate(
                  item.certificados[
                    item.certificados.length - 1
                  ].fecha_caducidad.substring(0, 10)
                )
              : "-"
          }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="grey darken-3"
                v-on="on"
                v-bind="attrs"
                :style="item && !item.certificados ? 'visibility: hidden' : ''"
                @click="descargarCertificado(item)"
              >
                <v-icon>mdi-cloud-download</v-icon>
              </v-btn>
            </template>
            <span>Bajar certificado</span>
          </v-tooltip>

          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="green darken-2"
                v-on="on"
                v-bind="attrs"
                @click="showCertificado(item)"
              >
                <v-icon>mdi-cloud-upload</v-icon>
              </v-btn>
            </template>
            <span>Subir certificado</span>
          </v-tooltip>

          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="red darken-1"
                v-on="on"
                v-bind="attrs"
                @click="quitarRetencion(item.id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Quitar retención</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <!-- PAGINACION -->
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
    </LayoutPage>

    <AsignarRetenciones
      ref="dialogAsignar"
      :clienteId="cliente.id"
      @refreshData="getRetenciones()"
    />
    <SubirCertificado ref="dialogCertificado" @refreshData="getRetenciones()" />
  </div>
</template>

<script>
import { retencionesService, clientesService } from "@/services/administrar";
import moment from "moment";
import LayoutPage from "../../LayoutPage.vue";
import AsignarRetenciones from "./AsignarRetenciones.vue";
import SubirCertificado from "./SubirCertificado.vue";
import formatDate from "@/utils/formatDate";

export default {
  name: "RetencionesClientes",
  components: {
    LayoutPage,
    AsignarRetenciones,
    SubirCertificado,
  },
  data() {
    return {
      loading: false,

      headers: [
        { text: "Gravamen", value: "condicion" },
        { text: "Gravamen descripción", value: "gravamen" },
        {
          text: "Canal",
          value: "canal_de_pago",
        },
        { text: "Alícuota", value: "alicuota" },
        { text: "Alícuota opcional", value: "alicuota_opcional" },
        { text: "Rg2854", value: "rg2854" },
        { text: "Minorista", value: "minorista" },
        { text: "Monto mínimo", value: "monto_minimo" },
        { text: "Fecha de caducidad", value: "fechaCaducidad" },
        { text: "", value: "actions", width: "140px" },
      ],

      retenciones: [],
      cliente: { cliente: "" }, // valor por defectos antes de la llamada

      rowsPerPageArray: [10, 30, 50],
      rowsPerPage: 10, // cantidad de registros que se muestran en la tabla
      page: 1, // pagina actual de la tabla
      pageCount: 0, // cantidad de paginas

      formatDate,
    };
  },
  async mounted() {
    this.getClientData();
    this.getRetenciones();
  },

  methods: {
    async getRetenciones() {
      const ClienteId = this.$route.params.cliente_id;
      this.loading = true;

      try {
        const resp = await retencionesService.getDataById({
          ClienteId,
        });
        // const resp = await retencionesService.getAll();

        this.retenciones = resp.data?.retenciones || [];

        this.loading = false;
      } catch (error) {
        // console.error(error);
        this.loading = false;
        this.$toastr.e("Error al obtener las retenciones");
      }
    },

    async getClientData() {
      try {
        const id = this.$route.params.cliente_id;
        const data = await clientesService.getClientById({ id });
        this.cliente = data;
      } catch (error) {
        // console.error(error);
        this.$toastr.e("Error al obtener los datos del cliente");
      }
    },

    async quitarRetencion(RetencionId) {
      const ClienteId = this.$route.params.cliente_id;

      // pidiendo confirmación de usuario 🤔
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Esta seguro?",
        text: "Esta acción quitará la retención del cliente",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Si, continuar",
        denyButtonText: "Cancelar",
      });

      if (isConfirmed) {
        // si el usuario confirma ✅ -> llamar endpoint 🔥
        try {
          this.loading = true;

          const data = await retencionesService.quitarRetencion({
            ClienteId,
            RetencionId,
          });

          this.loading = false;
          this.$toastr.s(data.message);
          this.getRetenciones();
          this.$refs.dialogAsignar.$emit("refreshData");
        } catch (error) {
          // console.error(error);
          this.loading = false;
          this.$toastr.r("Error al quitar retención");
        }
      }
    },

    showCertificado(item) {
      const datos = {
        idCliente: this.$route.params.cliente_id,
        idRetencion: item.id,
        gravamen: item.condicion.gravamen.gravamen,
      };

      this.$refs.dialogCertificado.$emit("showCertificado", datos);
    },

    showDialog() {
      this.$refs.dialogAsignar.$emit("showDialog");
    },

    async descargarCertificado(item) {
      const cantCertificados = item.certificados.length;

      try {
        const params = {
          CertificadoId: item.certificados[cantCertificados - 1].id,
        };
        const data = await retencionesService.getCertificado(params);

        const certificado = document.createElement("a");
        certificado.href = data.data.content;
        certificado.download = data.data.file_name;
        certificado.click();
      } catch (error) {
        this.$toastr.e("Error al descargar certificado");
        // console.error(error);
      }
    },

    /*  Se encarga de verificar que la fecha de caducidad del ultimo registro
        en 'certificados' sea menor que la fecha actual, en ese caso colorea la
        fila en color rojo
    */
    rowColor(item) {
      if (item && item.certificados) {
        const cantCertificados = item.certificados.length;
        const lastCertificado = item.certificados[cantCertificados - 1];

        return lastCertificado.expired ? "red lighten-5" : "";
      }
    },
  },
};
</script>

<style scoped>
a[data-v-1b84c964] {
  color: #e6f1e3;
}

.theme--light.v-btn {
  color: rgb(93 175 118 / 87%);
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}
.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px;
}

.header-container {
  padding: 10px 10px 20px;
  display: flex;
  justify-content: space-between;
}

.v-tooltip__content {
  width: 135px;
}
</style>
