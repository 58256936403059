var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"1100"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold mb-2",staticStyle:{"font-family":"'Montserrat'"}},[_vm._v(" Seleccione una retención "),_c('v-spacer'),_c('v-text-field',{staticClass:"font-weight-regular",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',{staticClass:"pa-0",staticStyle:{"height":"460px"}},[_c('v-data-table',{staticClass:"elevation-0 mt-3 tabla",attrs:{"headers":_vm.headers,"items":_vm.retenciones,"loading":_vm.loading,"search":_vm.search,"loading-text":"Cargando retenciones....","no-data-text":"Sin retenciones","no-results-text":"Sin coincidencias","disable-sort":"","hide-default-footer":"","checkbox-color":"green","show-select":"","disable-pagination":"","single-select":true},on:{"click:row":function (item) { return (_vm.retencion = [item]); }},scopedSlots:_vm._u([{key:"item.condicion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.condicion.gravamen.gravamen)+" ")]}},{key:"item.gravamen",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.condicion.gravamen.descripcion)+" ")]}},{key:"item.minorista",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.minorista ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.minorista ? "Si" : "No")+" ")])]}},{key:"item.rg2854",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.rg2854 ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.rg2854 ? "Si" : "No")+" ")])]}}]),model:{value:(_vm.retencion),callback:function ($$v) {_vm.retencion=$$v},expression:"retencion"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-2","text":""},on:{"click":function($event){return _vm.reset()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"font-weight-bold",staticStyle:{"font-family":"'Montserrat'"},attrs:{"color":"green darken-1","text":"","disabled":_vm.retencion.length === 0,"loading":_vm.loading},on:{"click":function($event){return _vm.asignarRetencion()}}},[_vm._v(" Asignar retención ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }