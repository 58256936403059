var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"header-container"},[_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"primary"}},[_c('router-link',{attrs:{"to":"/administrar-clientes"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" arrow_back")])],1)],1),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.showDialog}},[_vm._v(" Asignar retención ")])],1),_c('LayoutPage',{attrs:{"title":("Retenciones del cliente: " + (_vm.cliente.cliente))}},[_c('v-data-table',{staticClass:"elevation-0 mt-3 tabla",attrs:{"headers":_vm.headers,"items":_vm.retenciones,"loading":_vm.loading,"loading-text":"Cargando retenciones....","no-data-text":"Sin retenciones","page":_vm.page,"items-per-page":_vm.rowsPerPage,"disable-sort":"","hide-default-footer":"","checkbox-color":"green","item-class":_vm.rowColor},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.condicion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.condicion.gravamen.gravamen)+" ")]}},{key:"item.gravamen",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.condicion.gravamen.descripcion)+" ")]}},{key:"item.minorista",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.minorista ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.minorista ? "Si" : "No")+" ")])]}},{key:"item.rg2854",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.rg2854 ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.rg2854 ? "Si" : "No")+" ")])]}},{key:"item.fechaCaducidad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.certificados ? _vm.formatDate( item.certificados[ item.certificados.length - 1 ].fecha_caducidad.substring(0, 10) ) : "-")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:(item && !item.certificados ? 'visibility: hidden' : ''),attrs:{"icon":"","color":"grey darken-3"},on:{"click":function($event){return _vm.descargarCertificado(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Bajar certificado")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green darken-2"},on:{"click":function($event){return _vm.showCertificado(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)]}}],null,true)},[_c('span',[_vm._v("Subir certificado")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red darken-1"},on:{"click":function($event){return _vm.quitarRetencion(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Quitar retención")])])]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('AsignarRetenciones',{ref:"dialogAsignar",attrs:{"clienteId":_vm.cliente.id},on:{"refreshData":function($event){return _vm.getRetenciones()}}}),_c('SubirCertificado',{ref:"dialogCertificado",on:{"refreshData":function($event){return _vm.getRetenciones()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }